


























import { Component, Vue } from "vue-property-decorator";
import CustomFormEdit from "@/components/CustomForm/Edit/index.vue";
import {
  CustomFormType,
  InternalProjectCreateOrUpdateDto,
  InternalProjectBudgetItemDto,
} from "@/api/appService";
import api from "@/api/index";
import InternalProjectBudgetItems from "@/components/InternalProjectBudgetItems/index.vue";

@Component({
  name: "InternalProjectCustomFormDto",
  components: {
    CustomFormEdit,
    InternalProjectBudgetItems,
  },
})
export default class InternalProjectCustomFormDemo extends Vue {
  dataId = 0;
  projectId!: number;
  formId = 0;
  details: InternalProjectBudgetItemDto[] = [];

  // 项目预算明细
  budgetItems: InternalProjectBudgetItemDto[] = [
    {
      id: undefined,
      projectId: undefined,
      projectBudgetCategory: undefined,
      projectBudgetType: undefined,
      itemName: undefined,
      content: undefined,
      unitPrice: undefined,
      number: undefined,
      unit: undefined,
      budgetQuotation: undefined,
      projectBudgetCapitalSource: undefined,
    },
  ];

  budgetForm: InternalProjectCreateOrUpdateDto = {
    budgetItems: this.budgetItems,
    applyMoney: 0,
    selfMoney: 0,
    otherMoney: 0,
    totalMoney: 0,
  };

  form: InternalProjectCreateOrUpdateDto = {};

  created() {
    if (this.$route.query.projectId) {
      this.projectId = Number(this.$route.query.projectId);
      api.customFormService
        .getCustomFormId({
          hostType: CustomFormType.InternalProject,
          hostId: String(this.projectId),
        })
        .then((res) => {
          this.formId = res ? Number(res) : 0;
        });
    } else {
      this.$message.error("url参数中缺少id");
    }
    if (this.$route.query.id) {
      this.dataId = Number(this.$route.query.id);
      api.internalProject.getDetail({ id: this.dataId }).then((res) => {
        if (res.budgetItems && res.budgetItems.length > 0) {
          this.budgetForm.budgetItems = res.budgetItems;
        }
        this.budgetForm.applyMoney = res.applyMoney;
        this.budgetForm.selfMoney = res.selfMoney;
        this.budgetForm.otherMoney = res.otherMoney;
        this.budgetForm.totalMoney = res.totalMoney;
      });
    } else {
      this.$message.error("url参数中缺少projectId");
    }
  }

  get hostType() {
    return CustomFormType.InternalProject;
  }

  get getData() {
    return api.internalProject.getDetail({ id: this.dataId });
  }

  handleSave() {
    (this.$refs.customForm as any).validate(async (valid: boolean) => {
      if (valid) {
        let canSave = true;
        if (this.form.budgetItems) {
          this.form.budgetItems.some((item) => {
            if (!item.content) {
              this.$message.error("项目预算的具体内容必填");
              canSave = false;
              return;
            }
          });
        }
        if (!canSave) {
          return;
        }

        const data = (
          this.$refs.customForm as any
        ).getRequestData() as InternalProjectCreateOrUpdateDto;
        if (this.dataId) {
          data.id = this.dataId;
        }
        data.formId = this.formId;
        data.budgetItems = this.form.budgetItems;
        data.applyMoney = this.form.applyMoney;
        data.selfMoney = this.form.selfMoney;
        data.otherMoney = this.form.otherMoney;
        data.totalMoney = this.form.totalMoney;
        api.internalProject.update({ body: data }).then((res) => {
          this.$message.success("更新成功");
          this.$router.back();
        });
      }
    });
  }

  cancel() {
    this.$router.go(-1);
  }

  //增加预算明细
  private addRow(r: number) {
    const obj = {
      id: undefined,
      projectId: undefined,
      projectBudgetCategory: undefined,
      projectBudgetType: undefined,
      itemName: undefined,
      content: undefined,
      unitPrice: undefined,
      number: undefined,
      unit: undefined,
      budgetQuotation: undefined,
      projectBudgetCapitalSource: undefined,
    };

    this.details = [...this.details!, obj];
  }

  // 删除预算明细
  private deleteRow(index: number) {
    this.details!.splice(index, 1);
  }

  changeBudgetForm(e: any) {
    Object.assign(this.form, this.form, e);
  }
}
