























import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import api from "@/api/index";
import SimpleUploadImage from "@/components/SimpleUploadImage/index.vue";
import {
  AttachmentHostType,
  DataDictionaryDto,
  ProjectBasicDetailUpdateDto,
  ProjectCreateOrUpdateDto,
  ProjectDto,
} from "@/api/appService";
import RoleSelect from "../../../components/RoleSelect/index.vue";

@Component({
  name: "ProjectManagerEdit",
  components: { RoleSelect, SimpleUploadImage },
})
export default class ProjectManagerEdit extends Vue {
  @Prop({ required: true, default: 0 })
  projectId!: number;

  show = false;

  projectTypes: DataDictionaryDto[] = [];

  projectDto: ProjectBasicDetailUpdateDto = {};

  submitting = false;

  @Watch("show")
  onShowChange(value: boolean): void {
    if (!value) {
      this.projectDto.projectManagerIds = [];
      return;
    }
    api.project
      .getProjectBasicDetail({ projectId: this.projectId })
      .then((res) => {
        this.projectDto = { ...res };
        this.projectDto.projectManagerIds =
          this.projectDto!.projectManagerIds!.map(Number);
        console.log(this.projectDto);
        // let array = this.projectDto.projectManagerIds?.split(',').filter(s=>s).map(s=>s)??[];
        // this.$set(this.projectDto,'projectTypesArrary',array);
        // this.projectDto.projectTypesArrary =
      });
  }

  handleSave(): void {
    this.submitting = true;
    api.project
      .updateProjectBasicDetail({ body: this.projectDto })
      .then((res) => {
        this.$message.success("更新成功");
        this.show = false;
        this.submitting = false;
      })
      .catch((res) => {
        this.submitting = false;
      });
  }
}
