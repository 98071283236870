





















import {
  Component,
  Vue,
  Inject,
  Prop,
  Watch,
  Model,
} from "vue-property-decorator";
import api from "@/api";
import { OrganizationStatus, OrganizationUnitDto } from "@/api/appService";

@Component({
  name: "RoleSelect",
})
export default class RoleSelect extends Vue {
  @Model("change", { required: true, default: "" })
  readonly value!: number | string;

  @Prop({ required: false, default: false })
  clearable!: boolean;

  @Prop({ required: false, default: "请选择项目负责人" })
  placeholder!: string;

  @Prop({ required: false, default: false })
  disabled!: boolean;

  @Prop({ required: false, default: false })
  bindValueName!: boolean;

  @Prop({ required: false, default: false })
  multiple!: boolean;

  @Prop({ required: true })
  roleName!: string;

  private val: number | string = "";
  userList: any[] = [];

  @Watch("value", { deep: true })
  valueChange(newVal: string | number) {
    this.val = newVal;
  }

  @Watch("val")
  onValueChange(newVal: string | number) {
    this.$emit("change", newVal);
  }

  created() {
    this.val = this.value;
    this.fetchData();
  }

  fetchData() {
    api.user
      .getUserListByRoleName({ roleName: this.roleName })
      .then((res: any) => {
        this.userList = res;
      });
  }
}
