

















import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import api from "@/api/index";
import SimpleUploadImage from "@/components/SimpleUploadImage/index.vue";
import {
  InternalProjectBudgetItemDto,
  InternalProjectCreateOrUpdateDto,
  InternalProjectDetailDto,
} from "@/api/appService";
import InternalProjectBudgetItems from "@/components/InternalProjectBudgetItems/index.vue";

@Component({
  name: "BudgetItemsEdit",
  components: {
    SimpleUploadImage,
    InternalProjectBudgetItems,
  },
})
export default class BudgetItemsEdit extends Vue {
  @Prop({ required: true, default: 0 })
  projectId!: number;

  show = false;

  projectDto: InternalProjectDetailDto = {};

  submitting = false;

  // 项目预算明细
  budgetItems: InternalProjectBudgetItemDto[] = [
    {
      id: undefined,
      projectId: undefined,
      projectBudgetCategory: undefined,
      projectBudgetType: undefined,
      itemName: undefined,
      content: undefined,
      unitPrice: undefined,
      number: undefined,
      unit: undefined,
      budgetQuotation: undefined,
      projectBudgetCapitalSource: undefined,
    },
  ];
  budgetForm: InternalProjectCreateOrUpdateDto = {
    budgetItems: this.budgetItems,
    applyMoney: 0,
    selfMoney: 0,
    otherMoney: 0,
    totalMoney: 0,
  };
  form: InternalProjectCreateOrUpdateDto = {};

  @Watch("show")
  onShowChange(value: boolean) {
    if (!value) {
      return;
    }
    api.internalProject
      .getDetailByProjectId({ projectId: this.projectId })
      .then((res) => {
        this.projectDto = { ...res };
        if (
          this.projectDto.budgetItems &&
          this.projectDto.budgetItems.length > 0
        ) {
          this.budgetForm.budgetItems = this.projectDto.budgetItems;
        } else {
          this.budgetItems[0].projectId = this.projectId;
          this.budgetForm.budgetItems = this.budgetItems;
        }
      });
  }

  handleSave() {
    let canSave = true;
    if (this.budgetForm.budgetItems) {
      this.budgetForm.budgetItems.some((item) => {
        if (!item.itemName) {
          this.$message.error("项目预算的子项目（活动）内容必填");
          canSave = false;
          return;
        }
        if (!item.content) {
          this.$message.error("项目预算的具体内容必填");
          canSave = false;
          return;
        }
      });
    }
    if (!canSave) {
      return;
    }

    this.submitting = true;
    const body = {
      budgetItems: this.budgetForm.budgetItems,
      projectId: this.projectDto.projectId,
      id: this.projectDto.id,
    };
    api.internalProject
      .updateBudgetItem({ body: body })
      .then((res) => {
        this.$message.success("更新成功");
        this.show = false;
        this.submitting = false;
      })
      .catch((res) => {
        this.submitting = false;
      });
  }

  changeBudgetForm(e: any) {
    Object.assign(this.form, this.form, e);
  }
}
